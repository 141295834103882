import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import he from 'he';

const NewsData = () => {
  const [newsData, setNewsData] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/rolebased/news`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setNewsData(data.newsData);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNewsData();
  }, []);

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          {t('Latest News')}
        </h2>
        {newsData.length === 0 ? (
          <p>{t('No data found')}.</p>
        ) : (
          <div className="bg-white shadow-md rounded-lg">
            {newsData.map((news, index) => (
              <div key={news.ID} className="border-b border-gray-200">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full flex justify-between items-center px-6 py-4 focus:outline-none"
                >
                  <span className="font-medium text-lg text-gray-700">
                    {news.TITULO}
                  </span>
                  {expanded === index ? (
                    <FaChevronUp className="h-6 w-6 text-gray-500" />
                  ) : (
                    <FaChevronDown className="h-6 w-6 text-gray-500" />
                  )}
                </button>
                {expanded === index && (
                  <div className="p-6 bg-gray-100">
                    <p className="text-sm text-gray-500 mb-2">
                      <strong>{t('Date')}:</strong>{" "}
                      {new Date(news.DATA).toLocaleString()}
                    </p>
                    {/* <p className="text-sm text-gray-500 mb-2">
                      <strong>{t('State')}:</strong> {news.ESTADO}
                    </p> */}
                    <div className="mb-4 overflow-x-auto">
                      <div dangerouslySetInnerHTML={{ __html: he.decode(news.DESCRICAO).replace(/<\s+/g, "<").replace(/\s+>/g, ">") }} />
                    </div>
                    <p className="text-sm text-gray-500">
                      <strong>{t('Inserted by')}:</strong> {news.INSERTED_BY}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>{t('Last Updated')}:</strong>{" "}
                      {new Date(news.DT_UPDATED).toLocaleString()}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NewsData;
