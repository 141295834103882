import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const InternalEntitiesData = () => {
  const [internalEntitiesData, setInternalEntitiesData] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchInternalEntitiesData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${apiUrl}/api/cadastre/internal-entities`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setInternalEntitiesData(data.internalEntitiesData);
      } catch (error) {
        console.error("Error fetching internal entities data:", error);
      }
    };

    fetchInternalEntitiesData();
  }, []);

  const renderNonNullFields = (data) => {
    return Object.entries(data)
      .filter(([key, value]) => value !== null)
      .map(([key, value], index) => (
        <tr key={index} className="hover:bg-gray-100">
          <td className="px-6 py-4 border-b border-gray-300 capitalize">
            {key.replace(/_/g, " ")}
          </td>
          <td className="px-6 py-4 border-b border-gray-300">{value}</td>
        </tr>
      ));
  };

  return (
    <>
      <div className="p-4 space-y-6 max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md mt-6 border">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          {t('Employee Cost')}
        </h1>
        {internalEntitiesData.length === 0 ? (
          <p>{t('No internal entities data available.')}</p>
        ) : (
          internalEntitiesData.map((data, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4">
              <h2 className="text-xl font-semibold mb-4">Cost #{index + 1}</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">
                        {t('Field')}
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">
                        {t('Value')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderNonNullFields(data)}</tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default InternalEntitiesData;
