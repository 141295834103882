import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const DelegationModal = ({ isVisible, onClose, onSubmit, title }) => {
  const [formData, setFormData] = useState({});
  const [dynamicRHIds, setDynamicRHIds] = useState({
    fromRHIdOptions: [],
    toRHIdOptions: [],
  });

  const { t } = useTranslation();
  // Decode token and extract RHID dynamically
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const RHID = decoded.RHID || "";
        const RHID_DESTINO = decoded.RHID_DESTINO || "";

        setDynamicRHIds({
          fromRHIdOptions: RHID ? [RHID] : [],
          toRHIdOptions: RHID_DESTINO ? [RHID_DESTINO] : [],
        });
      } catch (error) {
        console.error("Error decoding token:", error);
        toast.error("Invalid token. Please log in again.");
      }
    }
  }, []);

  if (!isVisible) return null;

  if (!isVisible) return null;

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Prepare payload to include only one RH.Id (either fromRHId or toRHId)
  const preparePayload = () => {
    return {
      empresa: formData.empresa || "",
      rHIDDestino: formData.toRHId || "", // Pass either To RH.Id or From RH.Id
      dtAdmissao: formData.dtAdmissao || "",
      dtInicio: formData.dtInicio || "",
      perfil: formData.perfil || "",
      dtFim: formData.dtFim || "",
      obs: formData.obs || "",
    };
  };

  // Submit form
  const handleSubmit = () => {
    // Ensure at least one of fromRHId or toRHId is provided
    const isRHIDValid = formData.fromRHId || formData.toRHId;

    // Validation: Check required fields
    const requiredFields = [
      "empresa",
      "dtAdmissao",
      "dtInicio",
      "perfil",
      "dtFim",
    ];
    const isFormComplete = requiredFields.every(
      (field) => formData[field] && formData[field].trim() !== ""
    );

    // Final validation: All fields + at least one RHId
    if (isFormComplete && isRHIDValid) {
      const payload = {
        empresa: formData.empresa,
        rHIDDestino: formData.toRHId || formData.fromRHId, // Pass only one RH.Id
        dtAdmissao: formData.dtAdmissao || "",
        dtInicio: formData.dtInicio,
        perfil: formData.perfil,
        dtFim: formData.dtFim,
        obs: formData.obs,
      };

      // Call parent onSubmit function with the payload
      onSubmit(payload);
      setFormData({}); // Clear form data
      onClose(); // Close the modal
    } else {
      toast.warning(
        "Please fill out all required fields and provide at least one RH.Id."
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-[80vh]">
        <h2 className="text-lg font-bold mb-4">{t(title)}</h2>

        {/* Company Dropdown */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("Company")}
          </label>
          <select
            name="empresa"
            onChange={handleChange}
            value={formData.empresa || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">{t("Select a company")}</option>
            <option value="DEMO1">DEMO1</option>
            <option value="QUAD">QUAD</option>
            <option value="WIPS">WIPS</option>
          </select>
        </div>

        {/* From RH.Id Dropdown */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("From RH.Id")}
          </label>
          <select
            name="fromRHId"
            onChange={handleChange}
            value={formData.fromRHId || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">{t("Select From RH.Id")}</option>
            {dynamicRHIds.fromRHIdOptions.map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
        </div>

        {/* To RH.Id Dropdown */}
        {/* <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("To RH.Id")}
          </label>
          <select
            name="toRHId"
            onChange={handleChange}
            value={formData.toRHId || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">{t("Select To RH.Id")}</option>
            {dynamicRHIds.toRHIdOptions.map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
        </div> */}

        {/* DT Admissao */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("DT Admissao")}
          </label>
          <input
            type="date"
            name="dtAdmissao"
            onChange={handleChange}
            value={formData.dtAdmissao || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* DT Inicio */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("DT Inicio")}
          </label>
          <input
            type="date"
            name="dtInicio"
            onChange={handleChange}
            value={formData.dtInicio || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Profile */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("Profile")}
          </label>
          <select
            name="perfil"
            onChange={handleChange}
            value={formData.perfil || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">{t("Select a Profile")}</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>

        {/* DT Fim */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("DT Fim")}
          </label>
          <input
            type="date"
            name="dtFim"
            onChange={handleChange}
            value={formData.dtFim || ""}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Observations */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            {t("Observations")}
          </label>
          <textarea
            name="obs"
            onChange={handleChange}
            value={formData.obs || ""}
            placeholder={t("Enter observations")}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-400 text-white rounded mr-2 hover:bg-gray-500"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DelegationModal;
