import React from "react";

import { jwtDecode } from "jwt-decode";

import { useTranslation } from "react-i18next";
import CalendarVaccencies from "./CalendarVacancies";
import DayDetails from "./DayDetails";
import CommonHeaderWithMenu from "../../../component/shared/CommonHeaderWithMenu";
import UserCommunications from "../UserCommunications";
import LegislationData from "../LegislationData";
import NewsData from "../NewsData";
import FaqData from "../FaqData";
import OnboardingData from "../OnboardingData";
import FooterNavigation from "../../FooterNavigation";

const AdminCommunication = () => {
  // Decode the token to get EMPRESA and RHID
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const rhid = decodedToken?.RHID || "";
  const ID_PERF_DEF = decodedToken?.ID_PERF_DEF || null;
  const isAdmin = () => decodedToken.ID_PERF_DEF === 7;
  const { t } = useTranslation();
  const title =
  ID_PERF_DEF === 7 ? "Calendar" : "Communication";
  return (
    <>
      <CommonHeaderWithMenu title={t(title)} />
      {isAdmin() ? (
        <>
          <div className="bg-white rounded-lg shadow-md p-4 mb-6">
            <h1 className="p-3 text-center text=xl font-bold">
              {t("Vacancies")}
            </h1>
            <CalendarVaccencies />
            <DayDetails />
          </div>
        </>
      ) : (
        <>
          <div className="mb-16 bg-white rounded-lg shadow-md mt-6">
            <CalendarVaccencies />
            {/* <DayDetails /> */}
            {/* <UserCommunications />
            <LegislationData />
            <NewsData />
            <FaqData />
            <OnboardingData /> */}
          </div>
        </>
      )}

      <FooterNavigation />
    </>
  );
};
export default AdminCommunication;
