import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { t } from "i18next";
import { Link } from "react-router-dom";
import plusVector from "../../assets/images/Vector.png";

const DocRequestIndex = () => {
    
    const [collection, setCollection] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const getCollection = async () => {

        try {
            const authToken = localStorage.getItem('token');

            const http = await fetch(`${apiUrl}/api/doc-requests`,{
                method: 'GET',
                headers: {
                    'Content-Type': "application/json",
                    "Authorization": `Bearer ${authToken}`
                }
            });

            const data = await http.json();

            
            setCollection(data.collection);
            setIsLoading(false);

        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    useEffect(() => {
        getCollection();
    },[]);

    // Check if loading
  if (isLoading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

    return (
        <div>
            <CommonHeaderWithMenu title="Doc Requests" />
            
            {collection?.length > 0 ? (
                collection.map((item, index) => (
                    <div className="flex flex-col p-4" key={index}>
                        <div className="-m-1.5 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="border rounded-lg shadow-md overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200 ">                                
                                        <tbody className="divide-y divide-gray-200">
                                        <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 "> # </th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{index+1}</td>
                                            </tr>
                                            <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 "> {t('Type')} </th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{item.TYPE}</td>
                                            </tr>
                                            <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 "> {t('Model')} </th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 "></td>
                                            </tr>
                                            <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('Company')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{ item.EMPRESA }</td>
                                            </tr>
                                            <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('RHID')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{item.RHID}</td>
                                            </tr>
                                            {/* <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('Phase')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 "></td>
                                            </tr> */}
                                            <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('Begin DT')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{item.DT_INI}</td>
                                            </tr>
                                            {/* <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('Code')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 ">{item.CD_DET_GD}</td>
                                            </tr> */}
                                            {/* <tr className="odd:bg-white divide-x divide-gray-200 even:bg-gray-100">
                                                <th className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left font-medium text-gray-800 ">{t('Internal REF')}</th>
                                                <td className="px-6 py-2 w-1/2 whitespace-nowrap text-md text-left text-gray-800 "></td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                
            ) : (
                <p className="text-center m-5 text-gray-500 text-lg"> No records found</p>
            )}

            <div className="fixed bottom-20 right-4">
                <Link to="/doc-requests/create">
                <img
                    src={plusVector}
                    alt="Plus Icon"
                    className="bg-[#8AB53E] p-3 rounded-full shadow-xl"
                />
                </Link>
            </div>

            <div className="my-20"></div>
            
            <FooterNavigation />
        </div>
    )
}


export default DocRequestIndex;