// // Calendar.js
// import React, { useState, useEffect } from "react";
// import { FiChevronLeft, FiChevronRight, FiPlus } from "react-icons/fi";
// import polygon from "../../assets/images/Polygon.png";
// import { useNavigate } from "react-router-dom";
// import AbsenceModal from "./AbsenceModal";
// import axios from "axios";
// import { jwtDecode } from "jwt-decode";

// const Calendar = () => {
//   const navigate = useNavigate();
//   const today = new Date();
//   const [year, setYear] = useState(today.getFullYear());
//   const [month, setMonth] = useState(today.getMonth() + 1);
//   const [currentDay] = useState(today.getDate());
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [absences, setAbsences] = useState([]);
//   const [rhid, setRhid] = useState(null); // Dynamically store the RHID
//   // console.log(absences)
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   const daysInMonth = new Date(year, month, 0).getDate();
//   const startDay = new Date(year, month - 1, 1).getDay();
//   const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//   const blankDays = Array.from({ length: startDay }, (_, i) => (
//     <div key={`blank-${i}`} className="p-2"></div>
//   ));

//   const handleAddAbsence = () => {
//     navigate("/add-absence");
//   };
//   const handleShowAll = () => {
//     navigate("/admin-view-adaptibility-vaccency-absense-all");
//   };
//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token);
//         setRhid(decodedToken?.RHID); // Dynamically set the RHID
//       } catch (error) {
//         console.error("Failed to decode token:", error);
//       }
//     }
//   }, []);

//   const handleDateClick = async (day) => {
//     setSelectedDate(day);
//     setModalOpen(true);
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(
//         `${apiUrl}/api/absences/calendar/${year}/${month}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );

//       const dataForDay = response.data.data[day] || [];
//       setAbsences(dataForDay);
//     } catch (error) {
//       console.error("Error fetching absences:", error);
//       setAbsences([]);
//     }
//   };

//   const handlePrevMonth = () => {
//     if (month === 1) {
//       setMonth(12);
//       setYear(year - 1);
//     } else {
//       setMonth(month - 1);
//     }
//     setSelectedDate(null);
//   };

//   const handleNextMonth = () => {
//     if (month === 12) {
//       setMonth(1);
//       setYear(year + 1);
//     } else {
//       setMonth(month + 1);
//     }
//     setSelectedDate(null);
//   };

//   return (
//     <div className="bg-white rounded-lg shadow-md p-4 mb-6">
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex gap-3 items-center">
//           {new Date(year, month - 1).toLocaleString("default", {
//             month: "long",
//           })}
//           , {year}
//           <img src={polygon} alt="" className="size-2" />
//         </div>
//         {rhid === 7002 ? (
//           <>
//             {" "}
//             <button
//               onClick={handleShowAll}
//               className="btn bg-[#8AB53E] px-3 py-1 text-white font-semibold rounded-md"
//             >
//               View All
//             </button>
//           </>
//         ) : (
//           <>
//             <button onClick={handleAddAbsence} className="text-gray-800">
//               <FiPlus className="font-semibold size-6" />
//             </button>
//           </>
//         )}
//       </div>

//       <div className="flex justify-between items-center mb-4">
//         <button onClick={handlePrevMonth} className="p-1">
//           <FiChevronLeft size={20} />
//         </button>
//         <div className="flex gap-3 items-center">
//           <h2 className="text-lg font-medium">
//             {new Date(year, month - 1).toLocaleString("default", {
//               month: "long",
//             })}
//             , {year}
//           </h2>
//         </div>
//         <button onClick={handleNextMonth} className="p-1">
//           <FiChevronRight size={20} />
//         </button>
//       </div>

//       <div className="grid grid-cols-7 gap-1 text-center text-sm">
//         {weekDays.map((day, index) => (
//           <div key={index} className="text-[#8AB53E] font-semibold">
//             {day}
//           </div>
//         ))}
//         {blankDays.concat(
//           Array.from({ length: daysInMonth }, (_, i) => {
//             const day = i + 1;
//             const isToday =
//               day === currentDay &&
//               today.getMonth() + 1 === month &&
//               today.getFullYear() === year;
//             const isSelected = day === selectedDate;

//             return (
//               <div
//                 key={day}
//                 className={`p-2 text-center cursor-pointer ${
//                   isToday ? "bg-[#8AB53E] text-white rounded-full" : ""
//                 } ${isSelected ? "bg-blue-500 text-white rounded-full" : ""}`}
//                 onClick={() => handleDateClick(day)}
//               >
//                 {day}
//               </div>
//             );
//           })
//         )}
//       </div>

//       <AbsenceModal
//         isOpen={modalOpen}
//         onClose={() => setModalOpen(false)}
//         absences={absences}
//       />
//     </div>
//   );
// };

// export default Calendar;

import React, { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight, FiPlus } from "react-icons/fi";
import polygon from "../../assets/images/Polygon.png";
import { useNavigate } from "react-router-dom";
import AbsenceModal from "./AbsenceModal";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Calendar = () => {
  const navigate = useNavigate();
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [currentDay] = useState(today.getDate());
  const [selectedDate, setSelectedDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [absences, setAbsences] = useState({});
  const [ID_PERF_DEF, setID_PERF_DEF] = useState("");
  const [rhid, setRhid] = useState(null); // Dynamically store the RHID
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const daysInMonth = new Date(year, month, 0).getDate();
  const startDay = new Date(year, month - 1, 1).getDay();
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const blankDays = Array.from({ length: startDay }, (_, i) => (
    <div key={`blank-${i}`} className="p-2"></div>
  ));

  const handleAddAbsence = () => {
    navigate("/add-absence");
  };

  const handleShowAll = () => {
    navigate("/admin-view-adaptibility-vaccency-absense-all");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setRhid(decodedToken?.RHID); // Dynamically set the RHID
        setID_PERF_DEF(decodedToken?.ID_PERF_DEF); // Dynamically set the RHID
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchAbsences = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiUrl}/api/absences/calendar/${year}/${month}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAbsences(response.data.data);
      } catch (error) {
        console.error("Error fetching absences:", error);
        setAbsences({});
      }
    };

    fetchAbsences();
  }, [year, month, apiUrl]);

  const handleDateClick = (day) => {
    setSelectedDate(day);
    setModalOpen(true);
  };

  const handlePrevMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    setSelectedDate(null);
  };

  const handleNextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    setSelectedDate(null);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-6">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-3 items-center">
          {new Date(year, month - 1).toLocaleString("default", {
            month: "long",
          })}
          , {year}
          <img src={polygon} alt="" className="size-2" />
        </div>
        {ID_PERF_DEF === 7 ? (
          <>
            {" "}
            <button
              onClick={handleShowAll}
              className="btn bg-[#8AB53E] px-3 py-1 text-white font-semibold rounded-md"
            >
              View All
            </button>
          </>
        ) : (
          <>
            <button onClick={handleAddAbsence} className="text-gray-800">
              <FiPlus className="font-semibold size-6" />
            </button>
          </>
        )}
      </div>

      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevMonth} className="p-1">
          <FiChevronLeft size={20} />
        </button>
        <div className="flex gap-3 items-center">
          <h2 className="text-lg font-medium">
            {new Date(year, month - 1).toLocaleString("default", {
              month: "long",
            })}
            , {year}
          </h2>
        </div>
        <button onClick={handleNextMonth} className="p-1">
          <FiChevronRight size={20} />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1 text-center text-sm">
        {weekDays.map((day, index) => (
          <div key={index} className="text-[#8AB53E] font-semibold">
            {day}
          </div>
        ))}
        {blankDays.concat(
          Array.from({ length: daysInMonth }, (_, i) => {
            const day = i + 1;
            const isToday =
              day === currentDay &&
              today.getMonth() + 1 === month &&
              today.getFullYear() === year;
            const isSelected = day === selectedDate;
            const hasAbsences = absences[day] && absences[day].length > 0;

            return (
              <div
                key={day}
                className={`p-2 text-center cursor-pointer ${
                  isToday ? "bg-[#8AB53E] text-white rounded-full" : ""
                } ${isSelected ? "bg-blue-500 text-white rounded-full" : ""} ${
                  hasAbsences ? "bg-red-200 rounded-full" : ""
                }`}
                onClick={() => handleDateClick(day)}
              >
                {day}
              </div>
            );
          })
        )}
      </div>

      <AbsenceModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        absences={absences[selectedDate] || []}
      />
    </div>
  );
};

export default Calendar;
