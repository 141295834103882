import React, { useState, useEffect } from "react";
import FooterNavigation from "../FooterNavigation";
import { Link, useParams } from "react-router-dom"; // To get ticket ID from the URL
import menuLeft from "../../assets/images/menu-left.png";
import { FaMagnifyingGlass } from "react-icons/fa6";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import he from 'he';
import { jwtDecode } from "jwt-decode";

const ETicketDetails = () => {
  const { ticketId } = useParams(); // Assuming you're passing the ticket ID via route params
  const [ticketDetails, setTicketDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  // console.log(ticketDetails);

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const token = localStorage.getItem("token"); // Get the token from localStorage

        if (!token) {
          setError("User is not authenticated. Please login first.");
          setLoading(false);
          return;
        }

        setLoginUser(jwtDecode(token));

        const response = await fetch(`${apiUrl}/api/etickets/${ticketId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include token in the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTicketDetails(data);
        } else {
          setError("Ticket not found.");
        }
      } catch (error) {
        setError("An error occurred while fetching the ticket details.");
      } finally {
        setLoading(false);
      }
    };

    fetchTicketDetails();
  }, [ticketId]);

  if (loading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!ticketDetails) {
    return <p>No ticket details available.</p>;
  }

  return (
    <>
      <CommonHeaderWithMenu title=" E-Tickets Details" />
      {/* <div className=" bg-tranparent  rounded-b-lg"> */}
      {/* Top Section with Icons and Title */}
      {/* <div className="flex items-center justify-between mb-4"> */}
      {/* Menu Icon */}
      {/* <div
            className="flex items-center justify-center w-10 h-10
         bg-transparent"
          >
            <img src={menuLeft} alt="" />
          </div> */}

      {/* Title */}
      {/* <h1 className="text-xl font-semibold text-[#373737]"></h1> */}

      {/* Notification Icon */}
      {/* <div
            className="relative flex items-center justify-center 
           "
          > */}
      {/* <FaMagnifyingGlass className="mr-2 size-6" /> */}
      {/* </div> */}
      {/* </div> */}

      {/* Search Bar */}
      {/* </div> */}
      <div className="p-4  mb-16">
        {/* Header */}

        {/* Details Section */}
        <div className="border border-[#D9D9D9] rounded-2xl p-4 mb-6 bg-[#F8F8F8]">
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Subject</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.ASSUNTO}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Date</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {new Date(
                ticketDetails?.ticket?.DT_INSERTED
              ).toLocaleDateString()}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Priority</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.PRIO_SOLICITADA}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Category</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.CATEGORIA}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">
              Request Type
            </h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.TIPO_PEDIDO}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Process</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.PROCESSO}
            </p>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-800">Message</h3>
            <div dangerouslySetInnerHTML={{ __html: he.decode(ticketDetails?.ticket?.DESCRICAO).replace(/<\s+/g, "<").replace(/\s+>/g, ">") }}>
            </div>
          </div>
        </div>

        {ticketDetails.replyMessage.length > 0 ? (
            ticketDetails.replyMessage.map((item, index) => (
              <div className="border border-[#D9D9D9] rounded-2xl p-4 mb-6 bg-[#F8F8F8]">
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-800">Date</h3>
                  <p className="text-[13px] text-[#7D7D7D]">
                    {new Date(
                      item.DT_INSERTED
                    ).toLocaleDateString()}
                  </p>
                </div>

                {(item.TO_ === loginUser.utilizador) ? (
                  <div className="mb-4">
                    <h3 className="text-sm font-semibold text-gray-800">From</h3>
                    <p> { item.FROM_ } </p>
                  </div>
                ):(
                  <div className="mb-4">
                    <h3 className="text-sm font-semibold text-gray-800">To</h3>
                    <p> { item.TO_ } </p>
                  </div>
                )}
                
                <div>
                  <h3 className="text-sm font-semibold text-gray-800">Message</h3>
                  <div dangerouslySetInnerHTML={{ __html: he.decode(item?.MSG).replace(/<\s+/g, "<").replace(/\s+>/g, ">") }} />
                </div>
              </div>
            ))
          ) : ""}

        {/* Reply Button */}
        <Link 
         className="block text-center w-full py-3 bg-[#8AB53E] text-white font-semibold rounded-lg shadow-md"
         to={`/reply-tickets/${ticketDetails.ticket.ID_ETICKET}`}
        >
          Reply
        </Link>
      </div>
      <FooterNavigation />
    </>
  );
};

export default ETicketDetails;
