import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import he from "he";
import { useTranslation } from "react-i18next";

const UserCommunications = () => {
  const [communications, setCommunications] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const {t} = useTranslation();

  useEffect(() => {
    const fetchCommunications = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/rolebased/communications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setCommunications(data.communications);
      } catch (error) {
        console.error("Error fetching communications:", error);
      }
    };

    fetchCommunications();
  }, []);

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      <div className="max-w-4xl mx-auto p-4 ">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          {t('Communication')}
        </h2>
        {communications.length === 0 ? (
          <p>{t('No communications available.')}</p>
        ) : (
          <div className="bg-white shadow-md rounded-lg">
            {communications.map((communication, index) => (
              <div
                key={index}
                className="border-b border-gray-200 rounded-t-lg overflow-hidden"
              >
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full flex justify-between items-center px-6 py-4 focus:outline-none bg-white hover:bg-gray-100 transition duration-200"
                >
                  <div className="flex items-center">
                    <span className="font-medium text-lg text-gray-700">
                      {communication.TITULO}
                    </span>
                    {/* <span className="ml-2 text-xs text-green-600 bg-green-200 px-2 py-1 rounded-full">
                      {communication.NOTIFICAR}
                    </span> */}
                  </div>
                  {expanded === index ? (
                    <FaChevronUp className="h-5 w-5 text-gray-500" />
                  ) : (
                    <FaChevronDown className="h-5 w-5 text-gray-500" />
                  )}
                </button>
                {expanded === index && (
                  <div className="p-6 bg-gray-50">
                    <p className="text-sm text-gray-500 mb-2">
                      <strong>Date:</strong>{" "}
                      {new Date(communication.DATA).toLocaleString()}
                    </p>
                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: he.decode(communication.DESCRICAO).replace(/<\s+/g, "<").replace(/\s+>/g, ">") }} />
                    {/* <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200">
                      Tomei Conhecimento
                    </button> */}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default UserCommunications;
