// import React, { useState, useEffect } from "react";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { useTranslation } from "react-i18next";

// const UserOutputs = () => {
//   const [outputsData, setOutputsData] = useState([]);
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const { t } = useTranslation();
//   useEffect(() => {
//     const fetchOutputsData = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           alert("You need to be logged in to view this data.");
//           return;
//         }
//         const response = await fetch(`${apiUrl}/api/user/outputs`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (!response.ok) {
//           throw new Error("Failed to fetch outputs data");
//         }
//         const data = await response.json();
//         setOutputsData(data.outputsData);
//       } catch (error) {
//         console.error("Error fetching outputs data:", error);
//         alert("An error occurred while fetching outputs data.");
//       }
//     };

//     fetchOutputsData();
//   }, [apiUrl]);

//   // Function to generate and download the PDF for a single output
//   const generatePDFSingle = (output) => {
//     const doc = new jsPDF();
//     doc.text(`Output Code: ${output.CD_OUTPUT}`, 14, 20);

//     const tableColumn = ["Field", "Value"];
//     const tableRows = [];

//     // Add the output data to the table
//     tableRows.push(["HEO Code", output.CD_HEO]);
//     tableRows.push(["Year", output.ANO]);
//     tableRows.push(["Month", output.MES]);
//     tableRows.push([
//       "Admission Date",
//       new Date(output.DT_ADMISSAO).toLocaleDateString(),
//     ]);
//     tableRows.push(["Company", output.EMPRESA]);
//     tableRows.push(["RHID", output.RHID]);
//     tableRows.push(["Flex Data", output.FLEX_1_V1 || "N/A"]);

//     doc.autoTable({
//       head: [tableColumn],
//       body: tableRows,
//       startY: 30,
//       theme: "striped",
//       styles: {
//         fontSize: 10,
//         cellPadding: 4,
//       },
//       headStyles: {
//         fillColor: [41, 128, 185], // Blue header background
//         textColor: [255, 255, 255], // White text
//       },
//     });

//     doc.save(`output_${output.CD_OUTPUT}.pdf`);
//   };

//   // Function to generate and download a combined PDF for all outputs
//   const generatePDFAll = () => {
//     const doc = new jsPDF();
//     doc.text("User Outputs", 14, 20);

//     const tableColumn = [
//       "Output Code",
//       "HEO Code",
//       "Year",
//       "Month",
//       "Admission Date",
//       "Company",
//       "RHID",
//       "Flex Data",
//     ];
//     const tableRows = [];

//     outputsData.forEach((output) => {
//       const outputData = [
//         output.CD_OUTPUT,
//         output.CD_HEO,
//         output.ANO,
//         output.MES,
//         new Date(output.DT_ADMISSAO).toLocaleDateString(),
//         output.EMPRESA,
//         output.RHID,
//         output.FLEX_1_V1 || "N/A",
//       ];
//       tableRows.push(outputData);
//     });

//     doc.autoTable({
//       head: [tableColumn],
//       body: tableRows,
//       startY: 30,
//     });

//     doc.save("doc.request.pdf");
//   };

//   return (
//     <div className="container mx-auto p-4">
//       {/* <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
//         Your Outputs
//       </h2> */}
//       {outputsData.length === 0 ? (
//         <p>{t('No data available')}.</p>
//       ) : (
//         <div className="shadow-md rounded-lg items-center">
//           {/* Button to Download All Outputs */}
//           <div className="text-center mb-4">
//             <button
//               onClick={generatePDFAll}
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//             >
//               {t('Download All as PDF')}
//             </button>
//           </div>

//           {outputsData.map((output, index) => (
//             <div key={index} className="border-b border-gray-200 p-6">
//               <div className="flex justify-between items-center">
//                 <div className="w-full">
//                   <table className="table-auto border-collapse w-full text-left">
//                     <thead>
//                       <tr>
//                         <th className="px-4 py-2 border bg-gray-200">{t('Field')}</th>
//                         <th className="px-4 py-2 border bg-gray-200">{t('Value')}</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Output Code')}</td>
//                         <td className="px-4 py-2 border">{output.CD_OUTPUT}</td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('HEO Code')}</td>
//                         <td className="px-4 py-2 border">
//                           {output.CD_HEO.length > 20
//                             ? `${output.CD_HEO.substring(0, 20)}...`
//                             : output.CD_HEO}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Year')}</td>
//                         <td className="px-4 py-2 border">{output.ANO}</td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Month')}</td>
//                         <td className="px-4 py-2 border">{output.MES}</td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Admission Date')}</td>
//                         <td className="px-4 py-2 border">
//                           {new Date(output.DT_ADMISSAO).toLocaleDateString()}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Company')}</td>
//                         <td className="px-4 py-2 border">{output.EMPRESA}</td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('RHID')}</td>
//                         <td className="px-4 py-2 border">{output.RHID}</td>
//                       </tr>
//                       <tr>
//                         <td className="px-4 py-2 border">{t('Flex Data')}</td>
//                         <td className="px-4 py-2 border">
//                           {output.FLEX_1_V1 && output.FLEX_1_V1.length > 20
//                             ? `${output.FLEX_1_V1.substring(0, 20)}...`
//                             : output.FLEX_1_V1 || "N/A"}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td colSpan="2" className="text-center py-4">
//                           <button
//                             onClick={() => generatePDFSingle(output)}
//                             className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//                           >
//                             {t('Download')} PDF
//                           </button>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserOutputs;

import jsPDF from "jspdf";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as pdfjsLib from "pdfjs-dist/webpack"; // Import pdf.js
import "jspdf-autotable"; // Ensure this is installed

const UserOutputs = () => {
  const [outputsData, setOutputsData] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Backend Base URL
  const { t } = useTranslation();
  const [pdfData, setPdfData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOutputsData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("You need to be logged in to view this data.");
          return;
        }

        const response = await fetch(`${apiUrl}/api/user/outputs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch outputs data");
        }

        const data = await response.json();
        setOutputsData(data.outputsData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching outputs data:", error);
        alert("An error occurred while fetching outputs data.");
      }
    };

    fetchOutputsData();
  }, [apiUrl]);

  // 🔹 Function to View Document (Opens in a new tab)
  const viewDocument = async (output) => {
    console.log(output);

    if (!output.CD_OUTPUT) {
      alert("No document available to view.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      // Simulating API response
      const response = await fetch(
        `${apiUrl}/api/user/outputs/view/${output.CD_OUTPUT}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch document.");
      }

      const fileBlob = await response.blob();
      const fileUrl = URL.createObjectURL(fileBlob);

      // Load the PDF using pdf.js
      const pdf = await pdfjsLib.getDocument(fileUrl).promise;
      let extractedText = "";

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const textItems = textContent.items.map((item) => item.str).join(" ");
        extractedText += textItems + "\n";
      }

      // Parse the extracted text
      const parsedData = parseExtractedText(extractedText);

      // Generate PDF with proper structured table
      const doc = new jsPDF({
        orientation: "landscape", // Makes it wider
        unit: "mm",
        format: [650, 210], // Wider than A4
      });

      doc.setFontSize(12);
      let y = 20;
      const addWrappedText = (doc, text, x, y, maxWidth) => {
        const splitText = doc.splitTextToSize(text, maxWidth);
        doc.text(splitText, x, y);
      };

      // **Header - Document Title**
      doc.setFont("helvetica", "bold");
      doc.text("Recibo Vencimento", 80, y);
      y += 10;

      // **Table - Document Details**
      const details = Object.entries(parsedData.details);
      details.forEach(([key, value]) => {
        doc.setFont("helvetica", "bold");
        doc.text(`${key}:`, 20, y);
        doc.setFont("helvetica", "normal");
        doc.text(`${value}`, 60, y);
        y += 8;
      });

      // **Table - ABONOS & DESCONTOS**
      // y += 10;
      // doc.setFont("helvetica", "bold");
      // doc.text("ABONOS (Earnings)", 20, y);
      // doc.text("DESCONTOS (Deductions)", 110, y);
      // y += 6;

      const abonosTable = parsedData.entries.abonos;
      const descontosTable = parsedData.entries.descontos;

      abonosTable.forEach((row, index) => {
        doc.setFont("helvetica", "normal");
        doc.text(row.Designacao, 20, y);
        doc.text(row.Qtd.toString(), 80, y);
        doc.text(row.Valor.toString(), 100, y);
        if (descontosTable[index]) {
          doc.text(descontosTable[index].Designacao, 110, y);
          doc.text(descontosTable[index].Valor.toString(), 180, y);
        }
        y += 6;
      });

      // **Footer - Total & Liquido**
      y += 10;
      doc.setFont("helvetica", "bold");
      // doc.text(`TOTAL ABONOS: EUR ${parsedData.totalAbonos}`, 20, y);
      // doc.text(`TOTAL DESCONTOS: EUR ${parsedData.totalDescontos}`, 110, y);
      // y += 8;
      // doc.text(`LIQUIDO: EUR ${parsedData.liquido}`, 20, y);

      // Save & Display the PDF
      const generatedPdfBlob = doc.output("blob");
      const generatedPdfUrl = URL.createObjectURL(generatedPdfBlob);

      // Open a new window with the embedded PDF
      const viewerHtml = `
            <html>
                <head>
                    <title>PDF Viewer</title>
                    <style>
                        body { font-family: Arial, sans-serif; padding: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                        th, td { padding: 8px; border: 1px solid #ddd; text-align: left; word-wrap: break-word;
        overflow-wrap: break-word; }
                        th { background-color: #f2f2f2; }
                        embed { width: 100%; height: 90vh; border: 1px solid #ddd; }
                        a { color: #007bff; text-decoration: none; }
                        a:hover { text-decoration: underline; }
                    </style>
                </head>
                <body>
                    <h2>Download & View PDF</h2>
                    <p>
                        <a href="${generatedPdfUrl}" download="Recibo_Vencimento.pdf">📄 Click here to Download PDF</a>
                    </p>
                    <h2>PDF Preview</h2>
                    <embed src="${generatedPdfUrl}" type="application/pdf" />
                </body>
            </html>
        `;

      // Open the dynamically generated document in a new tab
      const newWindow = window.open("", "_blank");
      newWindow.document.write(viewerHtml);
      newWindow.document.close();
    } catch (error) {
      console.error("Error opening document:", error);
      alert("Failed to view document.");
    }
  };
  const parseExtractedText = (text) => {
    const lines = text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line);

    return {
      details: {
        Número: extractField(lines, "Número"),
        // Colaborador: extractColaborador(lines), // Extract only RHID
        Admissão: extractDate(lines, "Admissão"), // Extract only date
        Antiguidade: extractDate(lines, "Antiguidade"), // Extract only date
        // Identificação: extractIdentification(lines), // Extract full name & address
        Categoria: extractField(lines, "Categoria"),
        NIF: extractField(lines, "NIF"),
        NISS: extractField(lines, "NISS"),
        IBAN: extractField(lines, "IBAN"),
      },
      entries: extractTableData(lines, "", ""), // Extract Abonos & Descontos
      // totalAbonos: extractField(lines, "TOTAL EUR"),
      // totalDescontos: extractField(lines, "TOTAL EUR", 1),
      // liquido: extractField(lines, "LIQUIDO EUR"),
      fiscal: extractUniqueFields(lines, [
        "Situação Civil",
        "Titular/Dependentes",
        "Deficientes",
        "I.R.S Base Mês",
        "Seg.Social Base Mês",
        "Base Acumulada",
        "Desconto Acumulado",
      ]),
    };
  };

  const extractField = (lines, fieldName) => {
    const match = lines.find((line) => line.includes(fieldName));
    return match ? match.split(fieldName)[1]?.trim() || "" : "";
  };
  const extractColaborador = (lines) => {
    const match = lines.find((line) => line.includes("Colaborador"));
    return match ? match.match(/\d+/)?.[0] || "" : "";
  };
  const extractDate = (lines, fieldName) => {
    const match = lines.find((line) => line.includes(fieldName));
    return match ? match.match(/\d{4}\.\d{2}\.\d{2}/)?.[0] || "" : "";
  };
  const extractIdentification = (lines) => {
    const startIndex = lines.findIndex((line) =>
      line.includes("Identificação")
    );
    if (startIndex === -1) return "";

    return lines.slice(startIndex + 1, startIndex + 3).join(" "); // Gets the next 2 lines
  };
  const extractTableData = (lines, abonosKey, descontosKey) => {
    const abonosIndex = lines.findIndex((line) => line.includes(abonosKey));
    const descontosIndex = lines.findIndex((line) =>
      line.includes(descontosKey)
    );

    const abonos = [];
    const descontos = [];

    for (let i = abonosIndex + 1; i < descontosIndex; i++) {
      const row = lines[i].split(/\s+/);
      abonos.push({
        Designacao: row[0],
        Qtd: row[1] || "",
        Valor: row[2] || "",
      });
    }

    for (let i = descontosIndex + 1; i < lines.length; i++) {
      const row = lines[i].split(/\s+/);
      descontos.push({ Designacao: row[0], Valor: row[1] || "" });
    }

    return { abonos, descontos };
  };
  const extractUniqueFields = (lines, fieldNames) => {
    const data = {};
    fieldNames.forEach((fieldName) => {
      data[fieldName] = extractField(lines, fieldName);
    });
    return data;
  };

  // 🔹 Function to Download Document
  const downloadDocument = async (output) => {
    const token = localStorage.getItem("token");
    if (!output.LINK_DOC) {
      alert("No document available for download.");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/user/outputs/download/${output.LINK_DOC}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download document.");
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = output.LINK_DOC; // Download with the same filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading document:", error);
      alert("Failed to download document.");
    }
  };

  // Check if loading
  if (isLoading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {outputsData.length === 0 ? (
        <p>{t("No data available")}.</p>
      ) : (
        <div className="shadow-md rounded-lg items-center">
          {outputsData.map((output, index) => (
            <div key={index} className="border-b border-gray-200 p-6">
              <div className="flex justify-between items-center">
                <div className="w-full">
                  <table className="table-auto border-collapse w-full text-left">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 border bg-gray-200">
                          {t("Field")}
                        </th>
                        <th className="px-4 py-2 border bg-gray-200">
                          {t("Value")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="px-4 py-2 border">{t("Output Code")}</td>
                        <td className="px-4 py-2 border">{output.CD_OUTPUT}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t("HEO Code")}</td>
                        <td className="px-4 py-2 border">
                          {output.CD_HEO.length > 20
                            ? `${output.CD_HEO.substring(0, 20)}...`
                            : output.CD_HEO}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t("Year")}</td>
                        <td className="px-4 py-2 border">{output.ANO}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t("Month")}</td>
                        <td className="px-4 py-2 border">{output.MES}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">
                          {t("Admission Date")}
                        </td>
                        <td className="px-4 py-2 border">
                          {new Date(output.DT_ADMISSAO).toLocaleDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t("Company")}</td>
                        <td className="px-4 py-2 border">{output.EMPRESA}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t("RHID")}</td>
                        <td className="px-4 py-2 border">{output.RHID}</td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="text-center py-4">
                          <button
                            onClick={() => viewDocument(output)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                          >
                            {t("View PDF")}
                          </button>

                          <button
                            onClick={() => downloadDocument(output)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          >
                            {t("Download PDF")}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserOutputs;
