import React from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import UserCommunications from "./UserCommunications";
import LegislationData from "./LegislationData";
import NewsData from "./NewsData";
import FaqData from "./FaqData";
import OnboardingData from "./OnboardingData";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const Communication = () => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const rhid = decodedToken?.RHID || "";
  const userRole = decodedToken?.ID_PERF_DEF || null; // Extract role dynamically

  // Dynamically check if the user has an admin role
  const isAdmin = () => {
    const storedRoles = JSON.parse(localStorage.getItem("roles")) || [];
    return userRole !== null && storedRoles.includes(userRole);
  };

  const { t } = useTranslation();
  const title = t("Communication");

  return (
    <>
      <CommonHeaderWithMenu title={t(title)} />
      {isAdmin() ? (
        <div className="bg-white rounded-lg shadow-md p-4 mb-16">
          <h1 className="p-3 text-center text-xl font-bold">
            {t("Admin Dashboard")}
          </h1>
          <UserCommunications />
          <LegislationData />
          <NewsData />
          <FaqData />
          <OnboardingData />
        </div>
      ) : (
        <div className="mb-16 bg-white rounded-lg shadow-md mt-6">
          <UserCommunications />
          <LegislationData />
          <NewsData />
          <FaqData />
          <OnboardingData />
        </div>
      )}

      <FooterNavigation />
    </>
  );
};

export default Communication;
