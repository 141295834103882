import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const EmployeeData = () => {
  const [employeeData, setEmployeeData] = useState(null);
  console.log(employeeData);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/cadastre/employee/data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setEmployeeData(data.employeeData[0]); // Assuming you only get one record back
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  if (!employeeData) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md mt-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          {t("Employee Information")}
        </h2>
        
        <table className="table-auto w-full border-collapse text-left">
          <tbody>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Company")}
              </th>
              <td className="px-4 py-2 text-left">{employeeData.EMPRESA}</td>
            </tr>
            {/* <tr>
              <th className="px-4 py-2 font-semibold text-gray-600">RHID</th>
              <td className="px-4 py-2">{employeeData.RHID}</td>
            </tr> */}
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Admission Date")}
              </th>
              <td className="px-4 py-2 text-left">
                {employeeData.DT_ADMISSAO}
              </td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Situation Code")}
              </th>
              <td className="px-4 py-2 text-left">
                {employeeData.CD_SITUACAO}
              </td>
            </tr>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Point")}
              </th>
              <td className="px-4 py-2 text-left">{employeeData.PONTO}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Phone")}
              </th>
              <td className="px-4 py-2 text-left">
                {employeeData.NR_MECANOGRAFICO}
              </td>
            </tr>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Extension")}
              </th>
              <td className="px-4 py-2 text-left">{employeeData.NR_INTERNO}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600 text-left">
                {t("Supervisor")} RHID
              </th>
              <td className="px-4 py-2 text-left">
                {employeeData.RHID_GESTOR_ADM}
              </td>
            </tr>
            {/* Add more fields as needed */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeData;
