import React, { useEffect, useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import EmployeeData from "./EmployeeData";
import RetributiveData from "./RetributiveData";
import ContractData from "./ContractData";
import InternalEntitiesData from "./InternalEntitiesData";
import { jwtDecode } from "jwt-decode";
import EmployeeList from "./admin/EmployeeList";
import EmployeeDetails from "./admin/EmployeeDetails";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cadastre = () => {
  const [userData, setUserData] = useState(null); // Regular user data
  const [employeeList, setEmployeeList] = useState([]); // Admin view employee list
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Selected employee details
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { t } = useTranslation();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Decode the token to get EMPRESA and RHID
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const rhid = decodedToken?.RHID || "";
  const isAdmin = decodedToken.ID_PERF_DEF === 7; // Admin check
  const navigate = useNavigate();

  // Fetch employee list (for admins)
  const fetchEmployeeList = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/cadastre/employee/info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Employee List:", data); // Add this line to debug the response
      setEmployeeList(data.managedEmployees);
      setIsLoading(false); // Set loading to false after fetching
    } catch (error) {
      console.error("Error fetching employee list:", error);
      setIsLoading(false); // Stop loading in case of error
    }
  };

  // Fetch regular user data
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/cadastre/user/data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUserData(data.userData);
      setIsLoading(false); // Set loading to false after fetching
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false); // Stop loading in case of error
    }
  };

  // Fetch data based on admin or user role
  useEffect(() => {
    if (isAdmin) {
      fetchEmployeeList();
    } else {
      fetchData();
    }
  }, [isAdmin]); // Depend on the isAdmin value

  // Check if loading
  if (isLoading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }
  const handleEmployeeSelect = (rhid) => {
    setSelectedEmployee(rhid); // Update the selectedEmployee state with the clicked rhid
    navigate(`/employee/${rhid}`); // Then navigate to the details page
  };

  return (
    <>
      <CommonHeaderWithMenu title="Cadastre" />
      {isAdmin ? (
        <>
          <EmployeeList
            employeeList={employeeList}
            onSelectEmployee={handleEmployeeSelect}
          />
          {selectedEmployee && <EmployeeDetails rhid={selectedEmployee} />}
        </>
      ) : (
        <>
          <div className="p-4 mb-16 ">
            <div className="flex justify-center mb-4">
              {userData.IMAGE_URL && (
                <img
                  src={userData.IMAGE_URL}
                  alt="User Profile"
                  className="w-32 h-32 rounded-full border-4 border-gray-300"
                />
              )}
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden border">
              <table className="table-auto w-full text-left">
                <tbody>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Name')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">{userData.NOME}</td>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Gender')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">
                      {userData.GENERO}
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Date of Birth')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">
                      {userData.DT_NASCIMENTO}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Marital Status')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">
                      {userData.ESTADO_CIVIL}
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Email')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">
                      {userData.EMAIL}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                      {t('Phone Number')}
                    </th>
                    <td className="px-4 py-3 text-gray-900">
                      {userData.TELEMOVEL_1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <EmployeeData />
            <RetributiveData />
            <ContractData />
            <InternalEntitiesData />
          </div>
        </>
      )}

      <FooterNavigation />
    </>
  );
};

export default Cadastre;
