import React, { useEffect, useState } from "react";
import { Table, Tabs, Spin, message } from "antd";
import CommonHeaderWithMenu from "../../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../../FooterNavigation";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Item from "antd/es/list/Item";

const AdaptibilityVaccencyAndAbsenseAll = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);
  // console.log(vacancies);
  const [absences, setAbsences] = useState([]);
  const [adaptabilities, setAdaptabilities] = useState([]);
  // console.log(adaptabilities);

  // Fetch data for all tables
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const [vacancyResponse, absenceResponse, adaptabilityResponse] =
          await Promise.all([
            axios.get(`${apiUrl}/api/admin/vacancies`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`${apiUrl}/api/absences/admin-absences`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            // axios.get(`${apiUrl}/api/admin/workflow/records`, {
            //   headers: { Authorization: `Bearer ${token}` },
            // }),
          ]);

        setVacancies(vacancyResponse.data.data || []);
        setAbsences(absenceResponse.data.data || []);
        // setAdaptabilities(adaptabilityResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchAdaptabilities = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          `${apiUrl}/api/admin/workflow/records`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAdaptabilities(response.data.allRecords || []);
      } catch (error) {
        console.error("Error fetching adaptability data:", error);
        message.error("Failed to fetch adaptability data.");
      } finally {
        setLoading(false);
      }
    };

    fetchAdaptabilities();
  }, [apiUrl]);

  // Define columns for vacancies
  const vacancyColumns = [
    { title: t('RHID'), dataIndex: "RHID", key: "RHID",
      render: (text, record) => `${record.RHID} - ${record.NOME}`
     },
    { title: t("Empresa"), dataIndex: "EMPRESA", key: "EMPRESA" },
    { title: t("Start Date"), dataIndex: "DT_INI", key: "DT_INI",
      render: (text) => new Date(text).toLocaleString()
     },
    { title: t("End Date"), dataIndex: "DT_FIM", key: "DT_FIM",
      render: (text) => new Date(text).toLocaleString()
     },
    { title: t("Estado"), dataIndex: "ESTADO", key: "ESTADO" },
    { title: "Name", dataIndex: "NOME", key: "NOME" },
  ];

  // Define columns for absences
  const absenceColumns = [
    { title: t("RHID"), dataIndex: "RHID", key: "RHID",
      render: (text, record) => `${record.RHID} - ${record.NOME}`
     },
    { title: t("OBS"), dataIndex: "OBS", key: "OBS" },
    { title: t("Empresa"), dataIndex: "EMPRESA", key: "EMPRESA" },
    { title: t("Start Date"), dataIndex: "DT_INI", key: "DT_INI",
      render: (text) => new Date(text).toLocaleString()
     },
    { title: t("End Date"), dataIndex: "DT_FIM", key: "DT_FIM",
      render: (text) => new Date(text).toLocaleString()
     },
    { title: t("Tipo Registo"), dataIndex: "TIPO_REGISTO", key: "TIPO_REGISTO" },
    { title: t("Estado"), dataIndex: "ESTADO", key: "ESTADO" },
  ];

  // Define columns for adaptabilities
  const adaptabilityColumns = [
    { title: t("RHID"), dataIndex: "RHID", key: "RHID",
      render: (text, record) => `${record.RHID} - ${record.NOME}`
    },
    { title: t("Empresa"), dataIndex: "EMPRESA", key: "EMPRESA" },
    { title: t("Start Date"), dataIndex: "DT_INI_DET", key: "DT_INI",
      render: (text) => new Date(text).toLocaleString()
     },
    { title: t("End Date"), dataIndex: "DT_FIM_DET", key: "DT_FIM",
      render: (text) => new Date(text).toLocaleString()
     },
    {
      title: t("Tipo Ocorrencia"),
      dataIndex: "TP_OCORRENCIA",
      key: "TP_OCORRENCIA",
    },
    {
      title: t("Duracao Minutos"),
      dataIndex: "DURACAO_MINUTOS",
      key: "DURACAO_MINUTOS",
    },
  ];

  return (
    <>
      <CommonHeaderWithMenu title={t("Calendar List")} />
      <div style={{ padding: "20px" }} className="mb-10">
        {loading ? (
          <Spin size="large" className="items-center" />
        ) : (
          <Tabs defaultActiveKey="1">
            <Item tab={t('Vacancies')} key="1">
              <Table
                dataSource={vacancies}
                columns={vacancyColumns}
                rowKey="RHID"
                bordered
                scroll={{ x: "max-content" }} // Enables horizontal scroll
                locale={{ emptyText: t("No vacancies data available") }}
              />
            </Item>
            <Item tab={t('Absences')} key="2">
              <Table
                dataSource={absences}
                columns={absenceColumns}
                rowKey="RHID"
                bordered
                scroll={{ x: "max-content" }} // Enables horizontal scroll
                locale={{ emptyText: t("No absences data available") }}
              />
            </Item>
            <Item tab={t("Adaptabilities")} key="3">
              <Table
                dataSource={adaptabilities}
                columns={adaptabilityColumns}
                rowKey="ROW_NO"
                bordered
                scroll={{ x: "max-content" }} // Enables horizontal scroll
                locale={{ emptyText: t("No adaptability data available") }}
              />
            </Item>
          </Tabs>
        )}
      </div>
      <FooterNavigation />
    </>
  );
};

export default AdaptibilityVaccencyAndAbsenseAll;
