import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeList = ({ employeeList, onSelectEmployee }) => {
  const { t } = useTranslation();
  // console.log(employeeList);

  return (
    <div className="p-4 mb-16">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        {t("Employee List")}
      </h2>
      <ul className="space-y-4">
        {employeeList.map((employee, index) => (
          <li
            key={`${employee.RHID}-${index}`} // Add index to make the key unique
            className="flex items-center p-4 bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out cursor-pointer"
            onClick={() => onSelectEmployee(employee.RHID)}
          >
            <div className="flex-grow">
              <h3 className="text-lg font-semibold text-gray-800">
                {employee.NOME}
              </h3>
              <p className="text-sm text-gray-500">
                <span className="font-medium">{t("Active")}: </span>
                {employee.ACTIVO === 'S' ? t('Yes') : t('No') }
              </p>
            </div>
            <div className="ml-auto text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmployeeList;
