import React, { useState, useEffect } from "react";
import FooterNavigation from "../FooterNavigation";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ETicketDetails = () => {
  const { ticketId } = useParams(); // Assuming you're passing the ticket ID via route params
  const [ticketDetails, setTicketDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [descricao, setDescricao] = useState("");
  const [loggedInUser, setLoggedInUser] = useState();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  // console.log(ticketDetails);

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("User is not authenticated. Please login first.");
      return;
    }

    const loggedInUser = jwtDecode(token);
    const toField =
      ticketDetails?.ticket?.FROM_ === loggedInUser.utilizador
        ? safeParseJSON(ticketDetails?.ticket?.TO_)
        : ticketDetails?.ticket?.FROM_;

    try {
      const response = await fetch(`${apiUrl}/api/etickets/reply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ID_ETICKET: ticketDetails?.ticket?.ID_ETICKET,
          FROM_: loggedInUser.utilizador,
          TO_: toField, // Fixed parsing issue
          DESCRICAO: descricao,
          INSERTED_BY: loggedInUser.utilizador,
        }),
      });

      if (response.ok) {
        toast.success("Ticket created successfully!");
        navigate("/tickets");
      } else {
        toast.error("Failed to create ticket");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      toast.error("An error occurred. Please try again.");
    }
  };


  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const token = localStorage.getItem("token"); // Get the token from localStorage

        if (!token) {
          setError("User is not authenticated. Please login first.");
          setLoading(false);
          return;
        }

        const decodedToken = jwtDecode(token);
        setLoggedInUser(decodedToken);

        const response = await fetch(`${apiUrl}/api/etickets/${ticketId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include token in the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTicketDetails(data);
        } else {
          setError("Ticket not found.");
        }
      } catch (error) {
        setError("An error occurred while fetching the ticket details.");
      } finally {
        setLoading(false);
      }
    };

    fetchTicketDetails();
  }, [ticketId]);

  if (loading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!ticketDetails) {
    return <p>No ticket details available.</p>;
  }

  const safeParseJSON = (data) => {
    try {
      return JSON.parse(data.replace(/&quot;/g, '"')); // Replace encoded quotes
    } catch (error) {
      console.error("Invalid JSON:", data, error);
      return data; // Return original string if parsing fails
    }
  };
  const decodeHtmlEntities = (str) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
  };

  const toField = decodeHtmlEntities(ticketDetails?.ticket?.TO_ || "");
  const parsedToField = safeParseJSON(toField);

  return (
    <>
      <CommonHeaderWithMenu title="Reply E-Tickets" />
      <div className="p-4  mb-16">
        {/* Header */}

        {/* Details Section */}
        <div className="border border-[#D9D9D9] rounded-2xl p-4 mb-6 bg-[#F8F8F8]">
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Subject</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.ASSUNTO}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">To</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.FROM_ === loggedInUser.utilizador
                ? Array.isArray(parsedToField)
                  ? parsedToField.join(", ")
                  : parsedToField
                : ticketDetails?.ticket?.FROM_}
            </p>
          </div>
          {/* <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Priority</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.PRIO_SOLICITADA}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Category</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.CATEGORIA}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">
              Request Type
            </h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.TIPO_PEDIDO}
            </p>
          </div> */}
          {/* <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-800">Process</h3>
            <p className="text-[13px] text-[#7D7D7D]">
              {ticketDetails?.ticket?.PROCESSO}
            </p>
          </div> */}
          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Message
            </label>
            <div className="mt-1 relative">
              <textarea
                onChange={(e) => setDescricao(e.target.value)}
                className="block w-full px-3 py-2  border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                rows="3"
                placeholder="Enter your description here..."
              />
            </div>
          </div>
        </div>

        {/* Reply Button */}
        <button
          onClick={handleSubmit}
          className="w-full py-3 bg-[#8AB53E] text-white font-semibold rounded-lg shadow-md"
        >
          Sent
        </button>
      </div>
      <FooterNavigation />
    </>
  );
};

export default ETicketDetails;
