import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { t } from "i18next";
import { Link } from "react-router-dom";
import plusVector from "../../assets/images/Vector.png";
import { Option } from 'antd/es/mentions';

const DocRequestCreate = () => {
    
    const [typesCollection, setTypesCollection] = useState({});
    const [type, setType] = useState("");
    const [model, setModel] = useState("");
    const [beginDT, setBeginDT] = useState("");
    const [DSP, setDSP] = useState("");

    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const getTypes = async () => {

        try {
            const authToken = localStorage.getItem('token');

            const http = await fetch(`${apiUrl}/api/doc-requests/getTypes`,{
                method: 'GET',
                headers: {
                    'Content-Type': "application/json",
                    "Authorization": `Bearer ${authToken}`
                }
            });

            const data = await http.json();

            if(http.ok){
                setTypesCollection(data.collection);
            }else{
                toast.error('Failed to fetch records');
            }

        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    const handleSubmit = async () => {
    
        const token = localStorage.getItem("token"); // Get the token from localStorage
    
        if (!token) {
          toast.error("User is not authenticated. Please login first.");
          return;
        }
    
        try {
          const response = await fetch(`${apiUrl}/api/doc-requests/create`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include token in the Authorization header
            },
            body: JSON.stringify({
              type,
              model,
              beginDT,
              DSP
            }),
          });
    
          if (response.ok) {
            toast.success("Request sent successfully!");
            navigate("/doc-requests");
          } else {
            toast.error("Failed to processing");
          }
        } catch (error) {
          console.error("Error creating ticket:", error);
          toast.error("An error occurred. Please try again.", error);
        }
    };

    useEffect(() => {
        getTypes();
    },[]);

    return (
        <div>
            <CommonHeaderWithMenu title={t('New Doc Requests')} />

            <div className="m-5 p-2 mb-52">
                <div className="space-y-4">
                    <div>
                        <label className="block text-[16px] font-medium text-[#373737]">{t('Type')}</label>
                        <div className="mt-1 relative">
                        <select
                            onChange={(e) => setType(e.target.value)}
                            className="block w-full px-3 py-3 border bg-[#F8F8F8]
                        border-[#D9D9D9] rounded-md  focus:outline-none
                            focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                        >
                            <option selected disabled>Select One</option>
                            {typesCollection.length > 0 ? (
                                typesCollection.map((item, index) => (
                                    <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white" value={`${item.ID}@${item.DATE}`} key={index}>
                                        { item.TYPE }
                                    </option>
                                ))
                            ) : (
                                <option>{t('No records found')}</option>
                            )}
                        </select>
                        </div>
                    </div>

                    <div>
                        <label className="block text-[16px] font-medium text-[#373737]">{t('Model')}</label>
                        <div className="mt-1 relative">
                        <select
                            onChange={(e) => setModel(e.target.value)}
                            className="block w-full px-3 py-3 border bg-[#F8F8F8]
                        border-[#D9D9D9] rounded-md  focus:outline-none
                            focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                        >
                            <option></option>
                        </select>
                        </div>
                    </div>

                    <div className="mb-4 sm:mb-8">
                        <label className="block mb-2 text-sm font-medium">{t('Begin DT')}</label>
                        <input 
                            onChange={(e) => setBeginDT(e.target.value)}
                            type="date" 
                            className="block w-full px-3 py-3 border bg-[#F8F8F8]
                        border-[#D9D9D9] rounded-md  focus:outline-none
                            focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm" 
                            />
                    </div>

                    <div className="mb-4 sm:mb-8">
                        <label className="block mb-2 text-sm font-medium">{t('DSP')}</label>
                        <textarea
                            onChange={(e) => setDSP(e.target.value)}
                            className="block w-full px-3 py-3 border bg-[#F8F8F8]
                        border-[#D9D9D9] rounded-md  focus:outline-none
                            focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm" rows="3"></textarea>
                    </div>


                    <button
                        onClick={handleSubmit}
                        className="w-full py-3 bg-[#8AB53E] text-white font-semibold rounded-lg shadow-md"
                    >
                        {t('Submit')}
                    </button>
                </div>
            </div>
            
            <FooterNavigation />
        </div>
    )
}


export default DocRequestCreate;