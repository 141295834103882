import React, { useState, useEffect } from "react";
import FooterNavigation from "../FooterNavigation";
import { v4 as uuidv4 } from "uuid"; // To generate unique ID
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaCross, FaSave } from "react-icons/fa";

const NewTickets = () => {
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState("");
  const [estado, setEstado] = useState("New");
  const [processs, setProcesss] = useState("");
  const [categoria, setCategoria] = useState("");
  const [prioSolicitada, setPrioSolicitada] = useState("");
  const [prioAtrib, setPrioAtrib] = useState("");
  const [requestType, setRequestType] = useState("");
  const [subject, setSubject] = useState("");
  const [descricao, setDescricao] = useState("");
  const [justifPrioAtrib, setJustifPrioAtrib] = useState("");
  const [lida, setLida] = useState("N");
  const [toOptions, setToOptions] = useState({});
  const {t} = useTranslation();

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const uniqueId = uuidv4(); // Generate a unique ID for ID_ETICKET

    // Extract user information from localStorage
    const token = localStorage.getItem("token"); // Get the token from localStorage

    if (!token) {
      toast.error("User is not authenticated. Please login first.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/etickets/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
        body: JSON.stringify({
          ID_ETICKET: uniqueId, // Use the userId extracted from the token
          TO_: to,
          CC: cc,
          ESTADO: estado,
          PROCESSO: processs,
          CATEGORIA: categoria,
          PRIO_SOLICITADA: prioSolicitada,
          PRIO_ATRIB: prioAtrib,
          TIPO_PEDIDO: requestType,
          ASSUNTO: subject,
          DESCRICAO: descricao,
          JUSTIF_PRIO_ATRIB: justifPrioAtrib,
          LIDA: lida, // Use the userId extracted from the token
        }),
      });

      if (response.ok) {
        toast.success("Ticket created successfully!");
        navigate("/tickets");
        // Optionally, reset the form fields after successful submission
        // Reset form fields...
      } else {
        toast.error("Failed to create ticket");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      toast.error("An error occurred. Please try again.", error);
    }
  };

  const getUserCollection = async () => {
    try
    {
      const AuthToken = localStorage.getItem("token");
      const http = await fetch(`${apiUrl}/api/cadastre`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${AuthToken}`
        }
      });

      const response = await http.json();
      let options = [];

      response.users.forEach((user, index) => {
        options.push({value: user.UTILIZADOR, label: user.UTILIZADOR});
      });

      setToOptions(options)
      
    } catch(error){
      toast.error('Something went wrong', error);
    }
  }

  const handleToSelectValue = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setTo(values);
  }

  useEffect(() => {
    getUserCollection();
  },[]);

  return (
    <>
      <CommonHeaderWithMenu title="New E-Tickets" />
      <div className="p-4 mb-16">
        

        {/* Form Fields */}
        <div className="space-y-6">
          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              To
            </label>
            <div className="mt-1 relative">
              <Select
                options={toOptions}
                isMulti
                onChange={handleToSelectValue}
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              CC
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="CC (if any)"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Subject')}
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Lorem Ipsum"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Priority')}
            </label>
            <div className="mt-1 relative">
              <select
                value={prioSolicitada}
                onChange={(e) => setPrioSolicitada(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  {t('Low')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Medium')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('High')}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Assigned Priority')}
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={prioAtrib}
                onChange={(e) => setPrioAtrib(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Assigned Priority"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Category')}
            </label>
            <div className="mt-1 relative">
              <select
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  {t('Software')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Hardware')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Network')}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Request Type')}
            </label>
            <div className="mt-1 relative">
              <select
                value={requestType}
                onChange={(e) => setRequestType(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  {t('Support')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Maintenance')}
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Installation')}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Process')}
            </label>
            <div className="mt-1 relative">
              <select
                value={processs}
                onChange={(e) => setProcesss(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  {t('Process')} 1
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Process')} 2
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  {t('Process')} 3
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Justification for Assigned Priority')}
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={justifPrioAtrib}
                onChange={(e) => setJustifPrioAtrib(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Justification"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              {t('Message')}
            </label>
            <div className="mt-1 relative">
              <textarea
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className="block w-full px-3 py-2  border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                rows="3"
              />
            </div>
          </div>

          <div className="flex gap-4 justify-between">
            <Link 
              to="/tickets" 
              className="w-1/2 py-3 text-center bg-red-600 text-white font-semibold rounded-lg shadow-md"> 
              Cancel
            </Link>

            <button
              onClick={handleSubmit}
              className="w-1/2 py-3 bg-[#8AB53E] text-white font-semibold rounded-lg shadow-md"
            >
              {t('Sent')}
            </button>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default NewTickets;
