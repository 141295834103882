import React, { useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import UserOutputs from "./UserOutpu";

const Outputs = () => {
  
  return (
    <>
      <CommonHeaderWithMenu title="Outputs" />
      <div className="mb-16">
        <UserOutputs />
      </div>
      <FooterNavigation />
    </>
  );
};

export default Outputs;
