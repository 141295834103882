import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import he from 'he';

const LegislationData = () => {
  const [legislationData, setLegislationData] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLegislationData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/rolebased/legislation`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLegislationData(data.legislationData);
      } catch (error) {
        console.error("Error fetching legislation data:", error);
      }
    };

    fetchLegislationData();
  }, []);

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          {t('Legislation')} & {t('Fiscalidade')}
        </h2>
        {legislationData.length === 0 ? (
          <p>{t('No data available')}.</p>
        ) : (
          <div className="bg-white shadow-md rounded-lg">
            {legislationData.map((item, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full flex justify-between items-center px-6 py-4 focus:outline-none"
                >
                  <span className="font-medium text-lg text-gray-700">
                    {item.TITULO}
                  </span>
                  {expanded === index ? (
                    <FaChevronUp className="h-6 w-6 text-gray-500" />
                  ) : (
                    <FaChevronDown className="h-6 w-6 text-gray-500" />
                  )}
                </button>
                {expanded === index && (
                  <div className="p-6 bg-gray-100">
                    <p className="text-sm text-gray-500 mb-2">
                      <strong>{t('Date')}:</strong>{" "}
                      {new Date(item.DATA).toLocaleString()}
                    </p>
                    {/* <p className="text-sm text-gray-500 mb-2">
                      <strong>{t('State')}:</strong> {item.ESTADO}
                    </p> */}
                    <div className="mb-4 overflow-x-auto">
                      <div dangerouslySetInnerHTML={{ __html: he.decode(item.DESCRICAO).replace(/<\s+/g, "<").replace(/\s+>/g, ">") }} />
                    </div>
                    {/* <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                      {t('Tomei Conhecimento')}
                    </button> */}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default LegislationData;
